<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import { SHA256 } from "crypto-js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      page: 1,
      perPage: 5,
      pages: [],
      submited: false,
      spanView: false,
      data: {},
      fileres: {},
      faculty: {},
      teachers: [],
      classTeacher: null,
      cycle: [
        { value: "Licence", label: "Licence" },
        { value: "Master", label: "Master" },
        { value: "Doctorat", label: "Doctorat" },
      ],
      years: [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
      ],
      title: "Filières",
      items: [
        {
          text: "Filières",
          href: "/",
        },
        {
          text: "Liste",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      annee_formation: {
        required: helpers.withMessage("Veuillez faire un choix", required),
        maxLength: helpers.withMessage("1 characteres maximum", maxLength(1)),
      },
      nom_filiere: {
        required: helpers.withMessage(
          "Veuillez saisir le nom de la filiere",
          required
        ),
        minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
        maxLength: helpers.withMessage("50 characteres maximum", maxLength(50)),
      },
      niveau_formation: {
        required: helpers.withMessage("Veuillez faire un choix", required),
      },
      code_filiere: {
        required: helpers.withMessage(
          "Veuillez saisir le code de la filiere",
          required
        ),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("7 characteres maximum", maxLength(7)),
      },
      montant_ecolage: {
        required: helpers.withMessage(
          "Veuillez saisir le montant de l'ecolage (FCFA)",
          required
        ),
        minLength: helpers.withMessage("5 characteres minimum", minLength(5)),
        maxLength: helpers.withMessage("8 characteres maximum", maxLength(8)),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.getAllFaculties();
  },

  methods: {
    initFacultyCreation() {
      this.submited = true;
      this.createFaculty();
    },

    createFaculty() {
      this.spanView = true;
      const self = this;
      this.v$.data.$touch();
      this.$store
        .dispatch("postRequest", { route: "api/departments ", data: this.data })
        .then(
          function (response) {
            self.v$.data.$reset();
            self.data = {};
            self.spanView = false;
            self.getAllFaculties();
            Swal.fire(
              response.data.message,
              "La filière a été créée avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    getTeachers() {
      this.$store
        .dispatch("getRequest", {
          route: "api/user/professeur",
          data: this.data,
        })
        .then((response) => {
          this.teachers = response.data.searchedUsers.map((element) => ({
            value: element.id,
            label: element.nom + " " + element.prenom,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initFacultyUpdate() {
      this.submited = true;
      this.updateFiliere();
    },

    updateFiliere() {
      const self = this;
      (this.admitData = {
        department_id: this.data.id,
        professeur_id: this.classTeacher,
        statut: "actif",
        annee_scolaire: "2021-2022",
      }),
        (this.spanView = true);
      this.$store
        .dispatch("postRequest", {
          route: "/api/historic",
          data: this.admitData,
        })
        .then(
          function (response) {
            self.v$.data.$reset();
            self.spanView = false;
            Swal.fire(response.data.message, "Succès!", "success");
          },
          function (error) {
            self.spanView = false;
            console.log(error);
          }
        );
    },

    getAllFaculties() {
      const self = this;
      this.$store
        .dispatch("getRequest", { route: "api/departments", data: this.data })
        .then(
          function (response) {
            self.fileres = response.data.filieres;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },
    navEncryptFiliere(filiere) {
      const filiereString1 = JSON.stringify(filiere);
      const encryptionKey1 = "Slim#9065";
      const encrypted1 = CryptoJS.AES.encrypt(filiereString1, encryptionKey1);
      const encryptedMessage1 = encrypted1.toString();
      localStorage.setItem("dataF", encryptedMessage1);

      const filiereString = JSON.stringify(filiere.id);
      const hash = SHA256(filiereString).toString();
      if (this.$route.params.foo == "planifier") {
        this.$router.push({ path: `/courses/planifier/${hash}` });
      } else if (this.$route.params.foo == "programmer") {
        this.$router.push({ path: `/courses/programmer/${hash}` });
      } else if (this.$route.params.foo == "presence") {
        this.$router.push({ path: `/courses/presence/${hash}` });
      } else if (this.$route.params.foo == "journal_presence") {
        this.$router.push({ path: `/courses/journal_presence/${hash}` });
      } else if(this.$route.params.foo == "notes"){
        this.$router.push({ path: `/courses/notes/${hash}` });
      } else if(this.$route.params.foo == "releves"){
        this.$router.push({ path: `/courses/releves/${hash}` });
      }
    },
    async deleteFaculty(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette alerte? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch("postRequest", {
              route: "api/departments ",
              data: { idenfiant: id },
            })
            .then(
              function (response) {
                self.fileres = response.data.filieres;
                Swal.fire({
                  title: "Supprimer",
                  text: "Faculté supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },
    // getFaculty (faculty) {
    //   this.faculty = faculty;
    //   console.log(faculty);

    // },
    getFaculty(faculty) {
      this.faculty = faculty;
      this.data = Object.assign({}, faculty);
      this.$nextTick(() => {
        this.data.niveau_formation = this.cycle.find(
          (option) => option.value === faculty.niveau_formation
        );
        this.data.annee_formation = this.years.find(
          (option) => option.value === faculty.annee_formation
        );
      });
    },
    setPages() {
      let numberOfPages = Math.ceil(this.fileres.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <!-- <div class="row justify-content-end mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" v-model="" placeholder="Search..." />
            </div>
          </div> -->

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" scope="col" data-sort="currency_name">Id</th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Classe
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Ecolage
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Titulaire
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Action
                  </th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="filiere in fileres" :key="filiere.id">
                  <th scope="row">{{ filiere.id }}</th>
                  <td>
                    {{ filiere.full_classe.name.toUpperCase() }}
                    {{ filiere.group?.toUpperCase() }}
                  </td>

                  <td>{{ filiere.full_classe.ecolage }}Fcfa</td>

                  <td
                    v-if="filiere.department_professors_history[0]?.professor"
                  >
                    {{
                      filiere.department_professors_history[0]?.professor?.nom
                    }}
                    {{
                      filiere.department_professors_history[0]?.professor
                        ?.prenom
                    }}
                  </td>

                  <td v-else class="text-danger">
                    <b>Aucun titulaire</b>
                  </td>

                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        v-if="this.$route.params.foo == 'planifier'"
                        href="javascript:void(0);"
                        @click="navEncryptFiliere(filiere)"
                        title="Programmer emploie du temps"
                        class="btn btn-success fs-20"
                        ><i class="mdi mdi-calendar"></i
                      ></a>
                      <a
                        v-if="this.$route.params.foo == 'programmer'"
                        href="javascript:void(0);"
                        title="Programmer cours"
                        @click="navEncryptFiliere(filiere)"
                        class="btn btn-success fs-20"
                      >
                        <i class="las la-clipboard"></i>
                      </a>
                      <a
                        v-if="this.$route.params.foo == 'presence'"
                        href="javascript:void(0);"
                        title="Entrer Presences"
                        @click="navEncryptFiliere(filiere)"
                        class="btn btn-success fs-20"
                      >
                        <i class="mdi mdi-account-check-outline"></i>
                      </a>
                      <a
                        v-if="this.$route.params.foo == 'journal_presence'"
                        href="javascript:void(0);"
                        title="Listes Presences"
                        @click="navEncryptFiliere(filiere)"
                        class="btn btn-success fs-20"
                      >
                        <i class="mdi mdi-format-list-checks"></i>
                      </a>
                      <a
                        v-if="this.$route.params.foo == 'notes'"
                        href="javascript:void(0);"
                        title="Entrer notes"
                        @click="navEncryptFiliere(filiere)"
                        class="btn btn-success fs-20"
                      >
                        <i class="ri-pencil-line"></i>
                      </a>
                      <a
                        v-if="this.$route.params.foo == 'releves'"
                        href="javascript:void(0);"
                        title="Voir notes etudiants"
                        @click="navEncryptFiliere(filiere)"
                        class="btn btn-warning fs-20"
                      >
                        <i class="ri-pages-line"></i>
                      </a>
                      <a
                        v-if="this.$route.params.foo == 'notes'"
                        href="javascript:void(0);"
                        title="Voir classe exams notes"
                        @click="this.$router.push({ path: `/courses/classe-notes/${filiere.id}` });"
                        class="btn btn-info fs-20"
                      >
                        <i class="ri-eye-line"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initFacultyCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Ajouter Filière
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Nom filière</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="formationInput"
                          v-model="data.nom_filiere"
                          :class="{
                            'is-invalid':
                              (v$.data.nom_filiere.$error &&
                                data.nom_filiere) ||
                              (v$.data.nom_filiere.$error && submited),
                          }"
                          placeholder="Formation"
                        />
                        <div
                          v-for="(item, index) in v$.data.nom_filiere.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.nom_filiere) ||
                              (v$.data.nom_filiere.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="cycle" class="form-label"
                          >Niveau formation</label
                        >
                        <Multiselect
                          class="form-control"
                          v-model="data.niveau_formation"
                          :class="{
                            'is-invalid':
                              (v$.data.niveau_formation.$error &&
                                data.niveau_formation) ||
                              (v$.data.niveau_formation.$error && submited),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="cycle"
                        />
                        <div
                          v-for="(item, index) in v$.data.niveau_formation
                            .$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.niveau_formation) ||
                              (v$.data.niveau_formation.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="annee" class="form-label">Année</label>
                        <Multiselect
                          class="form-control"
                          v-model="data.annee_formation"
                          :class="{
                            'is-invalid':
                              (v$.data.annee_formation.$error &&
                                data.annee_formation) ||
                              (v$.data.annee_formation.$error && submited),
                          }"
                          :close-on-select="true"
                          :searchable="false"
                          :show-labels="false"
                          :options="years"
                        />
                        <div
                          v-for="(item, index) in v$.data.annee_formation
                            .$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.annee_formation) ||
                              (v$.data.annee_formation.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <!--end col-->

                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="codeFiliere" class="form-label"
                          >Code de Filière</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="codeFiliere"
                          v-model="data.code_filiere"
                          :class="{
                            'is-invalid':
                              (v$.data.code_filiere.$error &&
                                data.code_filiere) ||
                              (v$.data.code_filiere.$error && submited),
                          }"
                          placeholder="Code de la Filière"
                        />
                        <div
                          v-for="(item, index) in v$.data.code_filiere.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.code_filiere) ||
                              (v$.data.code_filiere.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Frais de scolarité</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="ecolage"
                          v-model="data.montant_ecolage"
                          :class="{
                            'is-invalid':
                              (v$.data.montant_ecolage.$error &&
                                data.montant_ecolage) ||
                              (v$.data.montant_ecolage.$error && submited),
                          }"
                          placeholder="Frais de scolarité"
                        />
                        <div
                          v-for="(item, index) in v$.data.montant_ecolage
                            .$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              (item.$message && data.montant_ecolage) ||
                              (v$.data.montant_ecolage.$error && submited)
                            "
                            >{{ item.$message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <div
          class="modal fade bs-faculty-update"
          tabindex="-1"
          role="dialog"
          aria-labelledby="updateFacultyModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initFacultyUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="updateFacultyModal">Modifier</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Nom filière</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="formationInput"
                          placeholder="Formation"
                          v-model="faculty.nom_filiere"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="cycle" class="form-label"
                          >Niveau formation</label
                        >
                        <select
                          class="form-control"
                          v-model="faculty.niveau_formation"
                        >
                          <option
                            v-for="c in cycle"
                            :key="c.value"
                            :value="c.value"
                          >
                            {{ c.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="annee" class="form-label">Année</label>
                        <select
                          class="form-control"
                          v-model="faculty.annee_formation"
                        >
                          <option
                            v-for="y in years"
                            :key="y.value"
                            :value="y.value"
                          >
                            {{ y.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="codeFiliere" class="form-label"
                          >Code de Filière</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="codeFiliere"
                          placeholder="Code de la Filière"
                          v-model="faculty.code_filiere"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Frais de scolarité</label
                        >
                        <input
                          type="number"
                          class="form-control"
                          id="ecolage"
                          placeholder="Frais de scolarité"
                          v-model="faculty.montant_ecolage"
                        />
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
